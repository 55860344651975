<template>
  <div>
    <v-app>
      <div class="row dashboard_envios">
        <div class="col">
          <v-card>
            <v-card-title>
              Envíos en el sistema
              <v-spacer></v-spacer>
              <v-btn
                @click="generateMassiveTags"
                small
                text
                color="success"
                class="float-left"
                :disabled="!selected.length"
              >
                <v-icon left dark>mdi-format-list-checks</v-icon>Imprimir Seleccionadas
              </v-btn>
              <div class="mx-0">
                <v-btn color="primary" icon x-small dark v-show="selected.length == 1">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
              <v-btn class @click="fetchDataPaginated" text icon color="blue">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-card outlined tile elevation="1">
                <v-card-text style="padding-bottom: 0px;">
                  <div class="d-flex justify-space-between mb-4">
                    <v-text-field
                      v-model="search"
                      clearable
                      label="Buscar"
                      hide-details
                      dense
                      solo
                      @change="fetchDataPaginated"
                      class="mr-2"
                    ></v-text-field>
                    <v-checkbox label="Búsqueda exacta" v-model="busquedaExacta">
                      Búsqueda exacta
                    </v-checkbox>
                    <v-btn class="btn-starkoms-primary" dark style="min-width: 138px;" @click="fetchDataPaginated" >Buscar</v-btn>
                  </div>
                  <div class="d-flex justify-space-between">
                    <div class="d-flex flex-column">
                      <span class="text-h6 font-weight-regular black--text"><i class="icon-filter_calendar"></i> Filtro por rango de fecha</span>
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        style="border-bottom: 1px solid #000;"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            @click:clear="limpiarRango"
                            v-model="dateRangeText"
                            placeholder="Todos"
                            solo
                            readonly
                          ></v-text-field>
                        </template>
                        <v-date-picker id="datepicker-starkoms" @input="formatearRango" range v-model="dates"></v-date-picker>
                      </v-menu>
                    </div>
                    <div class="d-flex flex-column">
                      <span class="text-h6 font-weight-regular black--text"><i class="icon-shipments"></i> Filtro por courier</span>
                      <v-select
                        @change="verRefSelect"
                        class="ml-2 mr-2"
                        label="Filtro por courier"
                        ref="select_carrier"
                        :items="carrierList"
                        v-model="selectCarrier"
                        item-text="text"
                        @input="fetchDataPaginated"
                        solo
                      ></v-select>
                    </div>
                    <div class="d-flex flex-column">
                      <span class="text-h6 font-weight-regular black--text"><i class="icon-warehouses"></i> Filtro por bodega</span>
                      <v-select
                        class="ml-2 mr-2"
                        label="Filtro por bodega"
                        :items="warehousesList"
                        v-model="selectWarehouses"
                        item-text="name"
                        @input="fetchDataPaginated"
                        solo
                      ></v-select>
                    </div>
                    <div class="d-flex flex-column">
                      <span class="text-h6 font-weight-regular black--text"><v-icon>mdi-file-document-edit-outline</v-icon> Filtro por manifiesto</span>
                      <v-select
                        class="ml-2 mr-2"
                        label="Filtro por manifiesto"
                        :items="manifestConditionList"
                        v-model="selectManifestCondition"
                        item-text="name"
                        @input="fetchDataPaginated"
                        solo
                      ></v-select>
                    </div>
                    <div class="d-flex">
                      <v-btn
                        v-show="!!search || !!dateRangeText || selectCarrier != 'Todos' || selectWarehouses != 'Todos' || selectManifestCondition != 'Todos'"
                        @click="limpiarFiltros"
                        class="align-self-center" dark color="grey"
                      >Limpiar Filtros</v-btn>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="tableData"
                show-select
                v-model="selected"
                no-results-text="No hay resultados disponibles"
                no-data-text="No hay datos disponibles"
                loading-text="Cargando datos..."
                :sort-by="'id'"
                :sort-desc="true"
                class="elevation-1 datatable"

                :loading="dataTableLoading"
                :disable-pagination="true"
                :hide-default-footer="true"
                :items-per-page="selectItemsPerPage"
              >
              <template v-slot:item.order_id="{ item }">
                  <td v-if="item.order_id" class="text-center"><a @click="$router.push({name: 'orden por ref', params: {ref: item.order_reference}})">{{item.order_id}}</a></td>
                  <td v-if="!item.order_id" class="text-center">{{item.order_id}}</td>
                  
                </template>
                <template v-slot:item.shipping_number="{ item }">
                  <td v-if="item.tracking_carrier" class="text-center"><a :href="item.tracking_carrier" target="_blank" >{{item.shipping_number}}</a></td>
                  <td v-if="!item.tracking_carrier" class="text-center">{{item.shipping_number}}</td>
                </template>
                <template v-slot:item.updated_at="{ item }">
                  <td class="text-center">{{$moment(item.updated_at).format('LLLL')}}</td>
                </template>
                <template v-slot:item.estado="{ item }">
                  <td class="text-center">
                    <!--
                                    <b-button size="sm" v-if="item.name_order_state == 'Ingresado'" pill variant="success">Ingresado</b-button>
                                    <b-button size="sm" v-if="item.name_order_state == 'Preparando'" pill variant="info">Preparando</b-button>
                                    <b-button size="sm" v-if="item.name_order_state == 'Listo para enviar'" pill variant="info">Listo para enviar</b-button>                               
                                    <b-button size="sm" v-if="item.name_order_state == 'Entregado a courier'" pill variant="info">Entregado a courier</b-button>
                                    <b-button size="sm" v-if="item.name_order_state == 'En tránsito'" pill variant="info">En tránsito</b-button>
                                    <b-button size="sm" v-if="item.name_order_state == 'Entregado'" pill variant="info">Entregado</b-button>
                    -->
                  </td>
                </template>
                <template v-slot:item.manifest="{ item }">
                  <td class="text-center">
                      <div v-if="item.manifest != null ">
                          <v-btn text icon color="green">
                            <v-icon>mdi-check</v-icon>
                          </v-btn>
                      </div>
                      <div v-else>
                        <v-btn text icon color="pink">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                      </div>
                  </td>
                </template>
                <template v-slot:item.acciones="{ item }">
                  <td class="d-flex">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          default
                          color="#8C8C8C"
                          class="mr-2"
                          v-on="on"
                          @click="$router.push({name: 'envio', params: {envio: item.id}})"
                        >mdi-arrow-right-box</v-icon>
                      </template>
                      <span>Ver detalles</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          default
                          color="#8C8C8C"
                          class="mr-2"
                          v-on="on"
                          @click="$router.push({name: 'orden por ref', params: {ref: item.order_reference}})"
                        >mdi-cart-arrow-right</v-icon>
                      </template>
                      <span>Ver orden</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          default
                          color="#8C8C8C"
                          class="mr-2"
                          v-on="on"
                          @click="openDialogModificarNroSeguimiento(item)"
                        >mdi-truck-delivery</v-icon>
                      </template>
                      <span>Nro. Seguimiento</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }" v-if="(item.carrier.api_data != null && item.carrier_api_data_obj && item.carrier_api_data_obj.multi_package != 0)">
                        <v-icon
                          default
                          color="#8C8C8C"
                          class="mr-2"
                          v-on="on"
                          @click="openDialogModificarNroBultos(item)"
                        >mdi-counter</v-icon>
                      </template>
                      <span>Nro. Bultos</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon default color="#8C8C8C" class="mr-2" v-on="on" @click="openDialogRegenerarEtiqueta(item)" >mdi-barcode</v-icon>
                      </template>
                      <span>Regenerar Etiqueta</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.tracking_url">
                      <template v-slot:activator="{ on }">
                        <v-icon                          
                          style="font-size: 1.5rem !important;"
                          small
                          color="#8c8c8c"
                          class="mr-2"
                          v-on="on"
                          @click="openShippingLink(item.tracking_url)"
                        >mdi-truck-delivery</v-icon>
                      </template>
                      <span>Link Seguimiento</span>
                    </v-tooltip>
                  </td>
                </template>
                <template v-slot:item.label="{ item }">
                 <td>
                    <v-btn
                      large
                      color="success"
                      dark
                      v-if="item.pdf !== ''"
                      @click="openPdfLink(item)"
                    >
                      <v-icon left dark>mdi-file</v-icon>Ver PDF
                    </v-btn>
                  </td>
                </template>
              
              </v-data-table>
              <div class="d-flex justify-space-between mt-4">
                  <v-select style="max-width: 120px" @input="fetchDataPaginated" v-model="selectItemsPerPage" label="Registros por página" :items="[5,10,20,50,100]"></v-select>
                  <v-pagination @input="fetchDataPaginated" v-model="page" :length="totalPages"></v-pagination>
              </div>
              
              <br />
              <br />
              <v-select
                v-model="selectCourier"
                :items="couriersList"
                :rules="[v => !!v || 'Debes elegir un courier']"
                label="Seleccione un courier"
                required
              ></v-select>
              <br />
              <v-btn
                @click="finish"
                v-show="selected.length && selectCourier"
                small
                color="success"
                dark
                class="float-left"
              >
                <v-icon left dark>mdi-format-list-checks</v-icon>Generar Manifiesto
              </v-btn>
              
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-app>
    <dialog-modificar-nro-seguimiento @success="fetchDataPaginated"></dialog-modificar-nro-seguimiento>
    <dialog-modificar-nro-bultos @success="fetchDataPaginated"></dialog-modificar-nro-bultos>
    <dialog-regenerar-etiqueta @success="fetchDataPaginated"></dialog-regenerar-etiqueta>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import format from "date-fns/format";
import DialogModificarNroSeguimiento from "@/views/oms/Envios/DialogModificarNroSeguimiento.vue";
import DialogModificarNroBultos from "@/views/oms/Envios/DialogModificarNroBultos.vue";
import DialogRegenerarEtiqueta from "@/views/oms/Envios/DialogRegenerarEtiqueta.vue";
import EventBus from "@/event-bus";
export default {
  components: {
    DialogModificarNroSeguimiento,
    DialogModificarNroBultos,
    DialogRegenerarEtiqueta
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dateFormat: "D MMM",
      dateOne: "",
      dateTwo: "",
      carrierList: [],
      warehousesList: [],
      manifestConditionList: [],
      selectCarrier: 0,
      search: "",
      busquedaExacta: true,
      selected: [],

      tableData: [],
      dates: [],
      menu1: false,
      dateRangeText: "",

      selected: [],
      couriersList: [],
      selectCourier: undefined,
      selectWarehouses: "Todos",
      selectManifestCondition: "Todos",
      totalPages: 1,
      dataTableLoading: false,
      selectItemsPerPage: 10,
      page: 1,
      api_url: process.env.VUE_APP_API_URL,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard Envíos", route: "envios" },
    ]);
    // this.fetchCarrierList();
    this.fetchDataPaginated();
    this.fetchCouriers();
    this.fetchWarehouses();
    this.fetchManifestConditionList();
  },
  methods: {
    fetchCarrierList() {
      var vm = this;
      this.axios({
        url: "shippings/carriers/get_data_list",
        method: "GET",
      })
        .then((response) => {
          vm.carrierList = response.data;
          vm.carrierList.push({ text: "Todos", value: 0 });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    limpiarFiltros() {
      this.search = "";
      this.busquedaExacta = true;
      this.dateRangeText = "";
      this.selectCourier = "Todos";
      this.selectWarehouses = "Todos";
      this.selectManifestCondition = "Todos";
      this.fetchDataPaginated();
    },
    fetchData() {
      var vm = this;
      this.axios({
        url: "shippings/json",
        method: "GET",
        params: {
          "pagination[page]": "1",
          "pagination[pages]": "24",
          "pagination[perpage]": "10",
          "pagination[total]": "234",
          "sort[sort]": "asc",
          "sort[field]": "id",
          query: "",
        },
      })
        .then((response) => {
          /**Pagination */
          vm.pageCount = Math.ceil(response.data.data.length / 10);
          vm.tableData = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchDataPaginated(){
      this.dataTableLoading = true;
      var vm = this;
      const sortBy = 'id';
      const sortDesc = true;
      const page = this.page;
      const itemsPerPage = this.selectItemsPerPage;
      const search = this.search;
      const busquedaExacta = this.busquedaExacta;
      const filterManifestCondition = this.manifestConditionList.find(e => e.name == this.selectManifestCondition) ? this.manifestConditionList.find(e => e.name == this.selectManifestCondition).id : null;
      const filterWarehouse = this.warehousesList.find(e => e.name == this.selectWarehouses) ? this.warehousesList.find(e => e.name == this.selectWarehouses).id : null;
      const startDate = !(this.dateRangeText.split(' ~ ').length == 2) ? null : this.dateRangeText.split(' ~ ')[0];
      const endDate = !(this.dateRangeText.split(' ~ ').length == 2) ? null : this.dateRangeText.split(' ~ ')[1];
      const filterCarrier = this.selectCarrier ? this.selectCarrier : null;
      this.axios({
        url: "shippings/paginated/json",
        method: "GET",
        params: {
          page,
          perPage: itemsPerPage,
          sortBy: sortBy,
          sortDesc: sortDesc ? 1 : 0,
          search,
          busquedaExacta,
          startDate,
          endDate,
          filterWarehouse,
          filterCarrier,
          filterManifestCondition
        },
      })
      .then((response) => {
        let arreglo = [];
        if(response.data.data instanceof Object){
            Object.keys(response.data.data).forEach(key => {
                arreglo.push(response.data.data[key]);
            });
            vm.tableData = arreglo;
        }else{
            vm.tableData = response.data.data.map(e => e);
        }
        vm.totalPages = response.data.last_page;
        vm.dataTableLoading = false;
      })
      .catch((error) => {
        console.log(error);
        vm.dataTableLoading = false;
      });
    },
    filtroRangoFechas(value) {
      if (!this.dateRangeText || !this.dates.length) {
        return true;
      } else {
        if (
          this.$moment(value).isSameOrAfter(this.$moment(this.dates[0])) &&
          this.$moment(value).isSameOrBefore(this.$moment(this.dates[1]))
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    verRefSelect() {
      console.log("select change", this.$refs.select_carrier.selectedItems);
    },
    filtroCarrier(v) {
      if (this.$refs.select_carrier.selectedItems[0]) {
        if (this.$refs.select_carrier.selectedItems[0].text == "Todos")
          return true;
        return this.$refs.select_carrier.selectedItems[0].text == v;
      } else {
        return true;
      }
    },
    formatearRango() {
      if (!this.dates.length) {
        this.dateRangeText = "";
      } else {
        this.dateRangeText = this.dates.join(" ~ ");
      }
      this.fetchDataPaginated();
    },
    limpiarRango() {
      this.dates = [];
    },
    fetchCouriers() {
      var vm = this;
      this.axios({
        method: "GET",
        url: "shippings/couriers/data_select_input",
      })
        .then((response) => {
          vm.couriersList = response.data;
          vm.carrierList = response.data;
          vm.carrierList.push({ text: "Todos", value: 0 });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchWarehouses() {
        var vm = this;
        this.axios({
            url: "orders/warehouses/data_select_input",
            method: "GET",
        })
        .then((response) => {
          //console.log("fetchWarehouses response.data.data", response.data.data);
          vm.warehousesList = response.data.data;
          vm.warehousesList.unshift({ id: null, name: "Todos", order: null });
        })
        .catch((error) => {
          console.log(error);
          vm.dataTableLoading = false;
        });
    },
    fetchManifestConditionList() {
      var vm = this;

      vm.manifestConditionList.push({ id: "todos", name: "Todos" });
      vm.manifestConditionList.push({ id: "si", name: "Con manifiesto" });
      vm.manifestConditionList.push({ id: "no", name: "Sin manifiesto" });
    },
    finish() {
      console.log("finish");
      this.axios({
        url: "orders/bulk_generate_manifest",
        method: "POST",
        data: {
          shipments: this.selected.map((e) => e.id),
          carrier: this.selectCourier,
        },
      })
        .then((response) => {
          this.fetchDataPaginated();
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    generateMassiveTags() {
      console.log("generateMassiveTags");
      this.axios({
        url: "shippings/generate_massive_tags",
        method: "POST",
        data: {
          shipments: this.selected.map((e) => e.id)
        },
      })
      .then((response) => {
        this.fetchDataPaginated();
        console.log(response);
        window.open(response.data.pdf, '_blank');
        
      })
      .catch((error) => {
        console.log(error);
      });
    },
    openDialogModificarNroSeguimiento(shipment) {
      //this.$emit("openDialogModificarNroSeguimiento", shipment);
      EventBus.$emit("openDialogModificarNroSeguimiento", shipment);
    },
    openDialogModificarNroBultos(shipment) {
      EventBus.$emit("openDialogModificarNroBultos", shipment);
    },
    openDialogRegenerarEtiqueta(shipment) {
      EventBus.$emit("openDialogRegenerarEtiqueta", shipment);
    },
    openShippingLink(url)
    {
      window.open(url, '_blank');
    },
    openPdfLink(shipment)
    {
      console.log("PDF LINK");
      this.axios({
        url: "shippings/printed_date/"+shipment.id
      })
      .then((response) => {
        var openWindow = true;
        if (response.data.printed_alert){
          openWindow = confirm(
            `ATENCIÓN: Esta etiqueta ya fué impresa el ${this.$moment(response.data.printed).format('DD/MM/YYYY HH:mm')}. \n¿Desea continuar igualmente?`
          );
        }
        
        if (openWindow) {
          window.open(shipment.pdf, '_blank');
        }        
      })
      .catch((error) => {
        console.log(error);
      });
    },
  },
  computed: {
    headers() {
      return [
        { text: "# de orden", align: "left", sortable: false, value: "order_id" },
        {
          text: "Referencia",
          value:
            "order_reference" /*filter: v => (v+'').toLowerCase().includes(this['search'].toLowerCase()) */,
        },
        { text: "Número de Envío", value: "shipping_number" },
        { text: "Email", value: "customer_email" },
        {
          text: "Fecha",
          value: "updated_at",
          //filter: (v) => this["filtroRangoFechas"](v),
        },
        { text: "Estado", value: "current_state" },
        {
          text: "Carrier",
          value: "carrier_name",
          //filter: (v) => this["filtroCarrier"](v),
        },
        {
          text: "Manifiesto",
          value: "manifest",
          //filter: (v) => this["filtroCarrier"](v),
        },
        { text: "Acciones", value: "acciones", sortable: false },
        { text: "Etiqueta", value: "label", sortable: false },
      ];
    },
  },
};
</script>

<style>
.dashboard_envios #icono-color-gris::before,
.dashboard_envios .icon-warehouses:before,
.dashboard_envios .icon-shipments:before{
  color: #8c8c8c !important;
}
#datepicker-starkoms .v-picker__title{
  background: linear-gradient(90deg, rgb(191, 19, 36) 0%, rgb(249, 88, 18) 100%) repeat scroll 0% 0% !important;
  
}
#datepicker-starkoms .accent
{
    background: linear-gradient(90deg, rgb(191, 19, 36) 0%, rgb(249, 88, 18) 100%) repeat scroll 0% 0% !important;
}
#datepicker-starkoms .accent--text{
    color: #e55d43 !important
}
.v-input--selection-controls{
  margin-top: 0px !important;
  margin-right: 10px;
  max-width: 100px;
  font-weight: bold;
}
</style>